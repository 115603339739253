body {
    --primary: #00b0f0;
    --background: #ffffff;
    --background-dim: #f3f3f3;
    --background-dim-light: #f9f9f9;
    --primary-foreground: #ffffff;
    --foreground: #161616;
    --base-unit: 8px;
}

.sd-root-modern {
    background: #F9FAFB;
}
